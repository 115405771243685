import React from 'react';
import profileShot from '../assets/aboutMe.png';
import finn from '../assets/finn.png';
function AboutMe(props) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <h1 style={{ fontFamily: 'AgrandirRegular'}}>About Me</h1>
            <div style={{ display: 'flex', alignItems: 'center', width: '80%'}}>
                <img src={profileShot} style={{ width: '30%'}} />
                <p style={{ fontFamily: 'CGMedium', fontWeight: 100, paddingInline: 25, fontSize: 18, marginTop: 0}}>
                Hello friends! It's so nice to meet you! My name is Megan, and I'm thrilled to welcome you to my little corner of the internet. As a graduate from the University of Virginia in 2020 with a major in IT and Marketing, my journey into the professional world has been nothing short of exhilarating. Currently, I work in consulting, where every day presents a new challenge and an opportunity to learn. However, I have been craving an additional creative outlet to share my greatest passions outside of work and a platform to build a community of people with similar interests.
                I grew up in Northern Virginia, spending most of my life apprehensive of change, wondering if I would ever leave "home". In the past year, I have finally started embracing change head-on, and I am so excited to help others do the same. 
                </p>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', width: '80%'}}>
                <p style={{ fontFamily: 'CGMedium', fontWeight: 100, paddingInline: 25, fontSize: 18, marginTop: 0}}>
                Last year, I took a major step by both tying the knot and buying a home in the picturesque town of Simpsonville, South Carolina. It's been a whirlwind of excitement, and I'm so grateful for all the experiences and growth these life changes have brought.
                Especially when life gets busy, I need an outlet to clear my head. Running has always been that outlet, and I recently achieved a significant milestone by completing a marathon this past spring. There's something incredibly empowering about pushing your limits and finding strength within yourself, one stride at a time.
                In addition to my love for running, my heart is filled with love for my little Cavachon puppy, Finn, who brings endless joy and adorable chaos to our home.
                </p>
                <img src={finn} style={{ width: '30%'}} />
            </div>
            <p style={{ fontFamily: 'CGMedium', fontWeight: 100, paddingInline: 25, fontSize: 18, width: '80%'}}>
            Fashion has always been a source of inspiration and expression for me. Some of my favorite stores include Princess Polly, Sabo Skirt, Hello Molly, Lululemon, and various smaller boutiques that offer unique pieces and trends that resonate with my style.
            This blog will be a space where I share my favorite finds that I have come across on my journey as a homeowner, my experiences in the running world, and of course, my passion for fashion. Join me as I navigate this exciting chapter of my life, one blog post at a time. I can't wait to connect with you and embark on this adventure together!
            </p>
        </div>
    );
}

export default AboutMe;