import React, { useContext, useState } from 'react';
import DateBar from './DateBar';
import { Box, Button, FormControl, Input, InputLabel } from '@mui/material';
import { Link } from 'react-router-dom';
import UserContext from '../context/UserContext';
import { API, graphqlOperation } from 'aws-amplify';
import { createComment, deleteComment, updatePost } from '../graphql/mutations';
import likedHeart from '../assets/red_heart.png';
import unlikedHeart from '../assets/unliked_heart.png';
import send from '../assets/send.png';
import Carousel from './Carousel';

const baseUrl = "https://stridesinstyled800e9656a3b44fcb1c2fcb6e647546e03630-staging.s3.amazonaws.com/public/"
function PostCard({ post, handleDelete }) {
    console.log(post);
    const [p, setP] = useState(post);
    const [links, setLinks] = useState(p.productLinks.items);
    const [readMore, setReadMore] = useState(false);
    const [seeMore, setSeeMore] = useState(false);
    const { user, setUser } = useContext(UserContext);
    const [liked, setLiked] = useState(false);
    const [name, setName] = useState('');
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState(post.comments.items);
    const [boldNext, setBoldNext] = useState(false);

    const handleLikeToggle = async () => {
        console.log('toggle');
        if (liked) {
            const input = {
                id: p.id,
                likes: p.likes - 1
            };
            const postUpdate = await API.graphql(graphqlOperation(updatePost, { input: input}));
            setP(postUpdate.data.updatePost);
            setLiked(false);
        } else {
            const input = {
                id: p.id,
                likes: p.likes + 1
            };
            const postUpdate = await API.graphql(graphqlOperation(updatePost, { input: input}))
            setP(postUpdate.data.updatePost);
            setLiked(true);
        }
    };

    const postComment = async (e) => {
        e.preventDefault();
        const input = {
            name,
            comment,
            postCommentsId: p.id
        }
        const newPost = await API.graphql(graphqlOperation(createComment, { input: input }));
        setComments([newPost.data.createComment, ...comments]);
        setSeeMore(true);
        alert('Thank you for the comment! I appreciate all the support!');
    };

    const handleDeleteComment = async (id) => {
        await API.graphql(graphqlOperation(deleteComment, { input: { id: id }}));
        setComments(prev => prev.filter(c => c.id !== id));
    }

    const CommentCard = ({comment}) => {
        return (
            <div>
                {user ? <Button onClick={() => handleDeleteComment(comment.id)}>Delete</Button> : null}
                <h3 style={{ fontFamily: 'AgrandirRegular', fontSize: 12}}>{comment.name}</h3>
                <h3 style={{ fontFamily: 'CGMedium', fontWeight: 100, fontSize: 12}}>{comment.comment}</h3>
            </div>
        );
    }

    const renderFormattedText = (text) => {
        const lines = text.split('\n');
        return lines.map((line, index) => {
          const boldRegex = /\*\*(.*?)\*\*/g;
          const boldMatches = [...line.matchAll(boldRegex)];
    
          return (
            <React.Fragment key={index}>
              {index !== 0 && <br />}
              {boldMatches.length > 0 ? (
                boldMatches.map((match, i) => {
                  const [fullMatch, boldText] = match;
                  const startIndex = line.indexOf(fullMatch);
                  const beforeBold = line.substring(0, startIndex);
                  const afterBold = line.substring(startIndex + fullMatch.length);
                  return (
                    <React.Fragment key={i}>
                      {beforeBold && <span>{beforeBold}</span>}
                      <strong>{boldText}</strong>
                      {afterBold && <span>{afterBold}</span>}
                    </React.Fragment>
                  );
                })
              ) : (
                <span>{line}</span>
              )}
            </React.Fragment>
          );
        });
      };

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <DateBar date={p.createdAt} />
            <h1 style={{ fontFamily: 'AgrandirRegular', margin: 5 }}>{p.title}</h1>
            <h3 style={{ fontFamily: 'CGMedium', fontWeight: 100, marginTop: 0 }}>{p.subtitle}</h3>
            <div style={{ width: '80%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                <img src={liked ? likedHeart : unlikedHeart} onClick={handleLikeToggle} style={{ width: 25, height: 25 }}/>
                <p style={{ fontSize: 12}}>{p.likes}</p>
            </div>
            <Link to={p.link} target="_blank" style={{ background: 'black', display: 'flex', justifyContent: 'center', width: "80%", height: 400}}>
                <img src={baseUrl+p.postPhotoId} style={{ width: '100%', height: '100%', objectFit: 'contain' }}/>
            </Link>
            <Carousel links={links} setLinks={setLinks}/>
            <div style={{ width: "80%" }}>
                {!readMore ? <p style={{ fontFamily: 'CGMedium', fontWeight: 100, textOverflow: readMore ? null : 'ellipsis', whiteSpace: readMore ? null : 'nowrap', overflow: readMore ? null : 'hidden'}}>{p.description}</p> :
                <p style={{ fontFamily: 'CGMedium', fontWeight: 100}}>
                    {renderFormattedText(p.description)}
                </p>}
            </div>
            <Button sx={{ background: 'black', width: '50%', marginTop: 1 }} onClick={() => { readMore ? setReadMore(false) : setReadMore(true) }} variant='contained'>
                {readMore ? "Read less" : "Read more"}
            </Button>
            {user ? <Button sx={{ background: 'red', width: '50%', marginTop: 1 }} onClick={() => handleDelete(p)} variant='contained'>
                Delete Post
            </Button> : null}
            <Box component="form" method='post' onSubmit={postComment} autoComplete="off" sx={{ display: 'flex', display: 'column', justifyContent: 'space-around', alignItems: 'center', width: '80%', height: '100%', border: '1px solid black', marginBlock: 5, padding: 5 }}>
                <h3 style={{ fontFamily: 'Balloon', fontSize: 14, margin: 0}}>Leave a comment</h3>
                <div style={{ display: 'flex', width: '100%'}}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '80%'}}>
                        <FormControl required sx={{ marginBlock: 2}}>
                            <InputLabel htmlFor="name">Name</InputLabel>
                            <Input id="name" name="name" onChange={(e) => setName(e.target.value)} value={name}/>
                        </FormControl>
                        <FormControl required>
                            <InputLabel htmlFor="comment">Comment</InputLabel>
                            <Input id="comment" name="comment" onChange={(e) => setComment(e.target.value)} value={comment}/>
                        </FormControl>
                    </div>
                    <Button type='submit'><img src={send} style={{ width: 45, height: 35}} /></Button>
                </div>
                <Button sx={{ background: 'black', width: '50%', marginTop: 1, color: 'white' }} onClick={() => seeMore ? setSeeMore(false) : setSeeMore(true)}>{seeMore ? "Hide Comments" : "Show Comments"}</Button>
                {seeMore ? comments.length > 0 && comments ? comments.sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt)).map(comment => <CommentCard key={comment.id} comment={comment} />) : <h3 style={{ fontFamily: 'CGMedium', fontWeight: 100, fontSize: 14}}>Currently no comments</h3> : null}
            </Box>
        </div>
    );
}

export default PostCard;