import React, { useContext } from 'react';
import { Outlet, Link } from "react-router-dom";
import insta from "../assets/instagram.png";
import tiktok from "../assets/tiktok.png";
import pinterest from "../assets/pinterest.png";
import { useMediaQuery } from '@mui/material';
import UserContext from '../context/UserContext';
import { Auth } from 'aws-amplify';

function Layout(props) {
  const { user } = useContext(UserContext);
    const matches850 = useMediaQuery('(min-width: 850px');
    const matches600 = useMediaQuery('(min-width: 600px');
    return (
        <div
        style={{
          backgroundColor: "#E7DFD8",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            background: "black",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
            marginTop: 25,
            fontFamily: "AgrandirRegular",
          }}
        >
          <Link to="/"><h3 style={{ color: "white", textAlign: "center", fontSize: matches850 ? 24 : matches600 ? 16 : 12 }}>Home</h3></Link>
          <Link to="/fashion"><h3 style={{ color: "white", textAlign: "center", fontSize: matches850 ? 24 : matches600 ? 16 : 12 }}>Fashion</h3></Link>
          <Link to="/decor"><h3 style={{ color: "white", textAlign: "center", fontSize: matches850 ? 24 : matches600 ? 16 : 12 }}>Home Decor</h3></Link>
          <Link to="/running"><h3 style={{ color: "white", textAlign: "center", fontSize: matches850 ? 24 : matches600 ? 16 : 12 }}>Running</h3></Link>
          <Link to="/wedding"><h3 style={{ color: "white", textAlign: "center", fontSize: matches850 ? 24 : matches600 ? 16 : 12 }}>Wedding</h3></Link>
          <Link to="/gifts"><h3 style={{ color: "white", textAlign: "center", fontSize: matches850 ? 24 : matches600 ? 16 : 12 }}>Gift Guides</h3></Link>
          <Link to="/about"><h3 style={{ color: "white", textAlign: "center", fontSize: matches850 ? 24 : matches600 ? 16 : 12 }}>About Me</h3></Link>
        </div>
        <Outlet />
        <div style={{ borderTop: '1px solid black', marginTop: 15, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
            <div style={{ width: '45%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <h3 style={{ fontFamily: 'AgrandirRegular', width: '100%'}}>STRIDES in STYLE</h3>
                <div style={{ display: "flex", flexDirection: "row", width: '100%' }}>
                    {/* Images for socials */}
                    <Link to="https://www.instagram.com/megan_morris916" target='_blank'><img src={insta} style={{ width: 50, height: 50}}/></Link>
                    <Link to="https://www.tiktok.com/@megan_morris916" target='_blank'><img src={tiktok} style={{ width: 50, height: 50}}/></Link>
                    <Link to="https://www.pinterest.com/megan_morris916" target='_blank'><img src={pinterest} style={{ width: 50, height: 50}}/></Link>
                </div>
            </div>
            <div style={{ width: '45%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <Link to="/"><p style={{ textDecoration: 'none', color: 'black', fontSize: 12, margin: 5}}>Home</p></Link>
                <Link to="/about"><p style={{ textDecoration: 'none', color: 'black', fontSize: 12, margin: 5}}>About Me</p></Link>
                <Link to="/admin"><p style={{ textDecoration: 'none', color: 'black', fontSize: 12, margin: 5}}>Dashboard</p></Link>
                {user ? <p onClick={() => Auth.signOut()} style={{ cursor: 'pointer', textDecoration: 'none', color: 'black', fontSize: 12, margin: 5}}>Sign out</p> : null}
            </div>
        </div>
        </div>
    );
}

export default Layout;