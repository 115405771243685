import { API, Storage, graphqlOperation } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { listPosts } from '../graphql/queries';
import PostCard from '../components/PostCard';
import { CircularProgress } from '@mui/material';
import { deletePhoto, deletePost, deleteProductLink } from '../graphql/mutations';

function GiftGuide(props) {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleDelete = async (post) => {
        console.log(post);
        try {
            await API.graphql(graphqlOperation(deletePhoto, { input: {id: post.postPhotoId} }));
            post.productLinks.items.forEach(async link => {
                if (link.productLinkPhotoId) {
                    await API.graphql(graphqlOperation(deletePhoto, { input: { id: link.productLinkPhotoId }}));
                    await Storage.remove(link.productLinkPhotoId);
                    await API.graphql(graphqlOperation(deleteProductLink, { input: { id: link.id }}));
                } else {
                    await API.graphql(graphqlOperation(deleteProductLink, { input: { id: link.id }}));
                }
            })
            await Storage.remove(post.postPhotoId);
            await API.graphql(graphqlOperation(deletePost, { input: {id: post.id}}));

            setPosts(prev => prev.filter(p => post.id !== p.id))
        } catch (e) {
            alert('There was a problem deleting the post: ' + JSON.stringify(e));
        }
    }
    useEffect(() => {
        setLoading(true);
        (async () => {
            const res = await API.graphql(graphqlOperation(listPosts, { filter: { category: {eq: 'gifts' }}}))
            setPosts(res.data.listPosts.items);
            setLoading(false);
        })()
    }, [])
    return (
        <div style={{ width: '80%' }}>
            {loading ? <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress /></div> : posts.length === 0 ?
            <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                <h1 style={{ fontFamily: 'CGMedium', fontWeight: 100, width: '100%', textAlign: 'center'}}>New gift guides coming soon!</h1>
            </div> : 
            posts.sort((x, y) => new Date(y.createdAt) - new Date(x.createdAt)).map(post => <PostCard key={post.id} post={post} handleDelete={handleDelete}/>)}
        </div>
    );
}

export default GiftGuide;