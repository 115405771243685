/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPost = /* GraphQL */ `
  mutation CreatePost(
    $input: CreatePostInput!
    $condition: ModelPostConditionInput
  ) {
    createPost(input: $input, condition: $condition) {
      title
      subtitle
      link
      photo {
        fileName
        id
        createdAt
        updatedAt
        __typename
      }
      description
      category
      comments {
        items {
          comment
          name
          id
          createdAt
          updatedAt
          postCommentsId
          __typename
        }
        nextToken
        __typename
      }
      likes
      productLinks {
        items {
          type
          html
          link
          id
          createdAt
          updatedAt
          postProductLinksId
          productLinkPhotoId
          __typename
        }
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      postPhotoId
      __typename
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost(
    $input: UpdatePostInput!
    $condition: ModelPostConditionInput
  ) {
    updatePost(input: $input, condition: $condition) {
      title
      subtitle
      link
      photo {
        fileName
        id
        createdAt
        updatedAt
        __typename
      }
      description
      category
      comments {
        items {
          comment
          name
          id
          createdAt
          updatedAt
          postCommentsId
          __typename
        }
        nextToken
        __typename
      }
      likes
      productLinks {
        items {
          type
          html
          link
          id
          createdAt
          updatedAt
          postProductLinksId
          productLinkPhotoId
          __typename
        }
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      postPhotoId
      __typename
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost(
    $input: DeletePostInput!
    $condition: ModelPostConditionInput
  ) {
    deletePost(input: $input, condition: $condition) {
      title
      subtitle
      link
      photo {
        fileName
        id
        createdAt
        updatedAt
        __typename
      }
      description
      category
      comments {
        items {
          comment
          name
          id
          createdAt
          updatedAt
          postCommentsId
          __typename
        }
        nextToken
        __typename
      }
      likes
      productLinks {
        items {
          type
          html
          link
          id
          createdAt
          updatedAt
          postProductLinksId
          productLinkPhotoId
          __typename
        }
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      postPhotoId
      __typename
    }
  }
`;
export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto(
    $input: CreatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    createPhoto(input: $input, condition: $condition) {
      fileName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto(
    $input: UpdatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    updatePhoto(input: $input, condition: $condition) {
      fileName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto(
    $input: DeletePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    deletePhoto(input: $input, condition: $condition) {
      fileName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      comment
      name
      id
      createdAt
      updatedAt
      postCommentsId
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      comment
      name
      id
      createdAt
      updatedAt
      postCommentsId
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      comment
      name
      id
      createdAt
      updatedAt
      postCommentsId
      __typename
    }
  }
`;
export const createProductLink = /* GraphQL */ `
  mutation CreateProductLink(
    $input: CreateProductLinkInput!
    $condition: ModelProductLinkConditionInput
  ) {
    createProductLink(input: $input, condition: $condition) {
      type
      html
      photo {
        fileName
        id
        createdAt
        updatedAt
        __typename
      }
      link
      id
      createdAt
      updatedAt
      postProductLinksId
      productLinkPhotoId
      __typename
    }
  }
`;
export const updateProductLink = /* GraphQL */ `
  mutation UpdateProductLink(
    $input: UpdateProductLinkInput!
    $condition: ModelProductLinkConditionInput
  ) {
    updateProductLink(input: $input, condition: $condition) {
      type
      html
      photo {
        fileName
        id
        createdAt
        updatedAt
        __typename
      }
      link
      id
      createdAt
      updatedAt
      postProductLinksId
      productLinkPhotoId
      __typename
    }
  }
`;
export const deleteProductLink = /* GraphQL */ `
  mutation DeleteProductLink(
    $input: DeleteProductLinkInput!
    $condition: ModelProductLinkConditionInput
  ) {
    deleteProductLink(input: $input, condition: $condition) {
      type
      html
      photo {
        fileName
        id
        createdAt
        updatedAt
        __typename
      }
      link
      id
      createdAt
      updatedAt
      postProductLinksId
      productLinkPhotoId
      __typename
    }
  }
`;
