import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../context/UserContext';
import { Button, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import backArrow from '../assets/backarrow.png';
import forwardArrow from '../assets/forwardarrow.png';
import { API, Storage, graphqlOperation } from 'aws-amplify';
import { deletePhoto, deleteProductLink } from '../graphql/mutations';

const baseUrl = "https://stridesinstyled800e9656a3b44fcb1c2fcb6e647546e03630-staging.s3.amazonaws.com/public/"

function Carousel({ links, setLinks }) {
    console.log(links);
    const matches = useMediaQuery('(min-width:750px)');
    const { user } = useContext(UserContext);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [visibleImages, setVisibleImages] = useState([]);

    const nextLink = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % links.length);
    };
  
    const prevLink = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? links.length - 1 : prevIndex - 1
      );
    };

    const handleDelete = (link, index) => {
        if (link.type === 'custom') {
            (async () => {
                console.log('photo id is: '+link.productLinkPhotoId);
                await API.graphql(graphqlOperation(deletePhoto, { input: { id: link.productLinkPhotoId }}));
                await Storage.remove(link.productLinkPhotoId);
                if (link.id) await API.graphql(graphqlOperation(deleteProductLink, { input: { id: link.id }}));
                const temp = [...links];
                temp.splice(index, 1);
                console.log(temp);
                setLinks(temp);
            })();
        } else {
            console.log('link is: '+JSON.stringify(link));
            if (link.id) {
                (async () => {
                    await API.graphql(graphqlOperation(deleteProductLink, { input: { id: link.id }}));
                })()
            }
            const temp = [...links];
            temp.splice(index, 1);
            console.log(temp);
            setLinks(temp);
        }
    }

    useEffect(() => {
        console.log(matches);
        if (matches && links.length > 5) {            
            const tempImages = [];
            for (let i = 0; i < 5; i++) {
                const index = (currentIndex + i) % links.length;
                tempImages.push(links[index]);
            }
            setVisibleImages(tempImages);
        } else if (!matches && links.length > 1) {
            const tempImages = [];
            for (let i = 0; i < 1; i++) {
                const index = (currentIndex + i) % links.length;
                tempImages.push(links[index]);
            }
            setVisibleImages(tempImages);
        } else {
            setVisibleImages(links);
        }

    }, [currentIndex, links, matches])

    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: matches ? '80%' : '100%', alignItems: 'center', justifyContent: 'center', paddingBlock: 10}}>
            {matches && links.length > 5 || !matches && links.length > 1 ? <img onClick={prevLink} src={backArrow} style={{ width: matches ? 50 : 25, height: matches ? 50 : 25 }}/> : null}
            {visibleImages.map((link, index) => (
                link.type === "product" ? 
                    <div key={index} style={{ width: 200, height: 200, margin: 2.5, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'black', position: 'relative'}}>
                        {user && <Button variant='contained' style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', position: 'absolute', right: 0, top: 0}} onClick={() => handleDelete(link, index)}>X</Button>}
                        <div dangerouslySetInnerHTML={{__html: link.html}}></div>
                    </div> :
                    <div key={index} style={{ width: 200, height: 200, margin: 2.5, display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'black', position: 'relative' }}>
                        {user && <Button variant='contained' style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', position: 'absolute', right: 0, top: 0}} onClick={() => handleDelete(link, index)}>X</Button>}
                        <Link to={link.link} target="_blank" style={{ background: 'black', display: 'flex', justifyContent: 'center'}}>
                            <img src={baseUrl+link.productLinkPhotoId} style={{ width: 150, objectFit: 'contain' }} />
                        </Link>
                    </div>
            ))}
            {matches && links.length > 5 || !matches && links.length > 1 ? <img onClick={nextLink} src={forwardArrow} style={{ width: matches ? 50 : 25, height: matches ? 50 : 25}}/> : null}
        </div>
    );
}

export default Carousel;