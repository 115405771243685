import React from 'react';
import wedding from "../assets/wedding.png";
import house from "../assets/house.png";
import marathon from "../assets/marathon.png";
import megan from "../assets/megan.png";
import heart from "../assets/heart_megan_blog.png";
import insta from "../assets/instagram.png";
import tiktok from "../assets/tiktok.png";
import pinterest from "../assets/pinterest.png";
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

function Main() {
    const matches850 = useMediaQuery('(min-width: 850px');
    const matches600 = useMediaQuery('(min-width: 600px');
    return (
        <>
            <div
                style={{
                border: "1px solid black",
                width: "80%",
                marginTop: 25,
                padding: 20,
                display: "flex",
                flexDirection: "row",
                alignItems: 'center'
                }}
            >
        <div style={{ width: "60%" }}>
          <h1 style={{ margin: 0, fontFamily: "AgrandirRegular", fontSize: matches850 ? 72 : matches600 ? 48 : 32 }}>STRIDES in STYLE</h1>
          <h3
            style={{
              fontFamily: "AgrandirThin",
              fontSize: matches850 ? 24 : matches600 ? 18 : 14,
              fontWeight: 100,
            }}
          >
            Hi there! I'm Megan, a 25-year-old enthusiast of all things fashion
            and a lover of life's most exciting milestones.
          </h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={heart} style={{ width: matches850 ?  100 : matches600 ? 80 : 60}} />
            <h1 style={{ fontFamily: "Balloon", fontSize: matches850 ? 48 : matches600 ? 32 : 24}}>In the past year...</h1>
          </div>
        </div>
        <div
          style={{
            width: "40%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              fontFamily: "Balloon",
              alignItems: "center",
            }}
          >
            {matches850 ? <h3>_______</h3> : matches600 ? <h3>_____</h3> : <h3>___</h3>}
            <h3
              style={{
                marginInline: 5,
                textAlign: "center",
                marginBottom: 5,
                fontSize: matches850 ? 18 : matches600 ? 14 : 12
              }}
            >
              Meet Megan
            </h3>
            {matches850 ? <h3>_______</h3> : matches600 ? <h3>_____</h3> : <h3>___</h3>}
          </div>
          <img src={megan} style={{ width: matches850 ? 250 : matches600 ? 175 : 100, borderRadius: matches850 ? 250 : matches600 ? 175 : 100 }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              fontFamily: "Balloon",
              alignItems: "center",
            }}
          >
            {matches850 ? <h3>_______</h3> : matches600 ? <h3>_____</h3> : <h3>___</h3>}
            <h3
              style={{ marginInline: 5, textAlign: "center", marginBottom: 5, fontSize: matches850 ? 18 : matches600 ? 14 : 12 }}
            >
              Follow Me
            </h3>
            {matches850 ? <h3>_______</h3> : matches600 ? <h3>_____</h3> : <h3>___</h3>}
          </div>
          <div style={{ display: "grid", gridTemplateColumns: '1fr 1fr 1fr', width: '100%' }}>
            <Link to="https://www.instagram.com/megan_morris916" target='_blank'><img src={insta} style={{ width: matches850 ? 100 : matches600 ? 75 : 50, height: matches850 ? 100 : matches600 ? 75 : 50 }} /></Link>
            <Link to="https://www.tiktok.com/@megan_morris916" target='_blank'><img src={tiktok} style={{ width: matches850 ? 100 : matches600 ? 75 : 50, height: matches850 ? 100 : matches600 ? 75 : 50 }}/></Link>
            <Link to="https://www.pinterest.com/megan_morris916" target='_blank'><img src={pinterest} style={{ width: matches850 ? 100 : matches600 ? 75 : 50, height: matches850 ? 100 : matches600 ? 75 : 50 }}/></Link>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 25,
          padding: 20,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "40%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={wedding} style={{ width: "80%" }} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "60%",
            alignItems: "center",
          }}
        >
          <h2
            style={{
              fontFamily: "AgrandirThin",
              fontSize: matches850 ? 48 : matches600 ? 32 : 24,
              textAlign: "center",
            }}
          >
            I said 'I do' to my best friend
          </h2>
          <p
            style={{
              textAlign: "center",
              fontFamily: "CGMedium",
              fontWeight: 100,
              fontSize: matches850 ? 24 : matches600 ? 18 : 14,
              width: matches850 ? "60%" : matches600 ? "70%" : "75%",
            }}
          >
            Check out my bridal outfits, decor, and planning advice{" "}
            <Link to="/wedding">
              <em>here</em>
            </Link>
          </p>
        </div>
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 25,
          padding: 20,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            alignItems: "center",
          }}
        >
          <h2
            style={{
              fontFamily: "AgrandirThin",
              fontSize: matches850 ? 48 : matches600 ? 32 : 24,
              textAlign: "center",
            }}
          >
            Became a proud homeowner
          </h2>
          <p
            style={{
              textAlign: "center",
              fontFamily: "CGMedium",
              fontWeight: 100,
              fontSize: matches850 ? 24 : matches600 ? 18 : 14,
              width: matches850 ? "60%" : matches600 ? "70%" : "75%",
            }}
          >
            Check out my best home decor finds{" "}
            <Link to="/decor">
              <em>here</em>
            </Link>
          </p>
        </div>
        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={house} style={{ width: "100%" }} />
        </div>
      </div>
      <div
        style={{
          width: "80%",
          marginTop: 25,
          padding: 20,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "40%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src={marathon} style={{ width: "90%" }} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "60%",
            alignItems: "center",
          }}
        >
          <h2
            style={{
              fontFamily: "AgrandirThin",
              fontSize: matches850 ? 48 : matches600 ? 32 : 24,
              textAlign: "center",
            }}
          >
            & even conquered the challenge of running a marathon
          </h2>
          <p
            style={{
              textAlign: "center",
              fontFamily: "CGMedium",
              fontWeight: 100,
              fontSize: matches850 ? 24 : matches600 ? 18 : 14,
              width: matches850 ? "60%" : matches600 ? "70%" : "75%",
            }}
          >
            Check out my running essentials & training advice{" "}
            <Link to="/running">
              <em>here</em>
            </Link>
          </p>
        </div>
      </div>
      <h3
        style={{
            fontSize: matches850 ? 24 : matches600 ? 18 : 14,
            width: matches850 ? "95%" : matches600 ? "90%" : "85%",
          textAlign: "center",
          fontFamily: "AgrandirThin",
          fontWeight: 100,
        }}
      >
        Join me on my journey as I blend my passion for style with the
        adventures of marriage, homeownership, and running – proving that you
        can have it all, one fabulous step at a time!
      </h3>
    </>
    );
}

export default Main;