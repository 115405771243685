import { useMediaQuery } from '@mui/material';
import React from 'react';

const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
function DateBar({ date }) {
    const matches850 = useMediaQuery('(min-width: 850px');
    const matches600 = useMediaQuery('(min-width: 600px');
    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <h1>__________</h1>
            <h1 style={{ fontFamily: "Balloon", fontSize: matches600 ? null : 24}}>{month[new Date(date).getMonth()]} {new Date(date).getDate()}, {new Date(date).getFullYear()}</h1>
            <h1>__________</h1>
        </div>
    );
}

export default DateBar;