import { Box, Button, FormControl, Input, InputLabel, MenuItem, Select, Switch, useMediaQuery } from '@mui/material';
import { API, Auth, Hub, Storage, graphqlOperation } from 'aws-amplify';
import React, { useContext, useEffect, useState } from 'react';
import { createPhoto, createPost, createProductLink } from '../graphql/mutations';
import UserContext from '../context/UserContext';
import Carousel from '../components/Carousel';

function Dashboard() {
    const { user, setUser } = useContext(UserContext);

    const matches = useMediaQuery('(min-width:600px)');
    let inputStyle;
    if (matches) inputStyle = { m: 2, width: '50%' };
    else inputStyle = { m: 2, width: '90%' };

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [link, setLink] = useState("");
    const [photo, setPhoto] = useState(null);
    const [image, setImage] = useState(null);
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("fashion");

    const [renderCarouselForm, setRenderCarouselForm] = useState(false);
    const [productLink, setProductLink] = useState({ productLinkPhotoId: null, link: '', html: '', type: 'product'});
    const [productLinks, setProductLinks] = useState([]);
    const [newLinkPhoto, setNewLinkPhoto] = useState(null);
    const [linkPhotoStaging, setLinkPhotoStaging] = useState(null);
    const [uploadingNewLink, setUploadingNewLink] = useState(false);

    const handleFileSelect = (e) => {
        setImage(URL.createObjectURL(e.target.files[0]));
        setPhoto(e.target.files[0]);
        // var ext = e.target.value.match(/\.([^\.]+)$/)[1];
        // switch (ext) {
        //     case 'jpg':
        //     case 'png':
                
        //     break;
        //     default:
        //         alert('This file type is not supported');
        //         e.target.value = '';
        // }
    }
    const handleSignIn = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await Auth.signIn(email, password);
            setLoading(false);
        } catch (e) {
            alert(e);
            setLoading(false);
        }
    }

    const resetFields = () => {
        setTitle("");
        setSubtitle("");
        setLink("");
        setPhoto(null);
        setImage(null);
        setDescription("");
        setCategory("fashion");
        setRenderCarouselForm(false);
        setProductLink({ productLinkPhotoId: null, link: '', html: '', type: 'product'});
        setProductLinks([]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        try {
            // store image
            const imageName = photo.name.split(" ").join("_");
            const photoRes = await API.graphql(graphqlOperation(createPhoto, { input: { fileName: imageName}}));
            await Storage.put(photoRes.data.createPhoto.id, photo);

            // create post
            const input = {
                title,
                subtitle,
                link,
                postPhotoId: photoRes.data.createPhoto.id,
                description,
                category,
            }
            const newPostRes = await API.graphql(graphqlOperation(createPost, { input: input }));

            // store product links and tie them to the new post
            productLinks.map(async link => {
                const plInput = {
                    type: link.type,
                    link: link.link,
                    html: link.html,
                    productLinkPhotoId: link.productLinkPhotoId,
                    postProductLinksId: newPostRes.data.createPost.id
                }
                await API.graphql(graphqlOperation(createProductLink, { input: plInput}));
            });

            alert('Successfully uploaded post!');
            resetFields();
            setSubmitting(false);
        } catch (e) {
            alert('Problem uploading post: '+ JSON.stringify(e));
            setSubmitting(false);
        }
    };

    const handleDescription = (e) => {
        console.log(e.target);
        setDescription(e.target.value);
    };

    const handleCarouselToggle = () => {
        setRenderCarouselForm(prev => !prev);
    };

    const toggleProductLinkType = (type) => {
        if (type === "product") {
            setProductLink({ productLinkPhotoId: null, link: '', html: '', type: "product" });
        } else {
            setProductLink({ productLinkPhotoId: null, link: '', html: '', type: "custom" });
        }
    };

    const handleFileSelectCustomLink = async (e) => {
        setLinkPhotoStaging(URL.createObjectURL(e.target.files[0]));
        setNewLinkPhoto(e.target.files[0]);
    };

    const addNewLink = async () => {
        console.log(productLink);
        setUploadingNewLink(true);
        if (productLink.type === "custom") {
            // alert("Custom links not active at the moment");
            // return;
            try {
                // create a new photo in the db
                const imageName = newLinkPhoto.name.split(" ").join("_");
                const photoRes = await API.graphql(graphqlOperation(createPhoto, { input: { fileName: imageName}}));
                
                // upload the file to s3
                await Storage.put(photoRes.data.createPhoto.id, newLinkPhoto);
                
                // add the photo to the product link
                setProductLinks([ ...productLinks, { ...productLink, productLinkPhotoId: photoRes.data.createPhoto.id } ]);
                setProductLink({...productLink, productLinkPhotoId: null, link: '', html: ''});
                setNewLinkPhoto(null);
                setLinkPhotoStaging(null);
                setUploadingNewLink(false);
            } catch (e) {
                alert('Problem uploading photo: '+ JSON.stringify(e));
                setUploadingNewLink(false);
                return;
            }
        } else {
            if (productLink.html === '') {
                alert('Need to add HTML into the product link field.');
                setUploadingNewLink(false);
                return;
            } else {
                setProductLinks([...productLinks, productLink]);
                setProductLink({...productLink, productLinkPhotoId: null, link: '', html: ''});
                setUploadingNewLink(false);
            }
        }
    };

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Box component="form" method='post' onSubmit={user ? handleSubmit : handleSignIn} autoComplete="off" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center', width: '80%', height: '100%', border: '1px solid black', marginBlock: 5 }}>
                {user ? 
                <>
                <h2 style={{ fontFamily: 'AgrandirRegular', fontWeight: 'bold', fontSize: 32}}>New Blog Post</h2>
                <Select
                    labelId='category'
                    name="category"
                    value={category}
                    label="Category"
                    onChange={(e) => setCategory(e.target.value)}
                    sx={inputStyle}
                >
                    <MenuItem value="fashion">Fashion</MenuItem>
                    <MenuItem value="running">Running</MenuItem>
                    <MenuItem value="decor">Home Decor</MenuItem>
                    <MenuItem value="wedding">Wedding</MenuItem>
                    <MenuItem value="gifts">Gift Guide</MenuItem>
                </Select>
                <FormControl required sx={inputStyle}>
                    <InputLabel htmlFor="title">Title</InputLabel>
                    <Input id="title" name="title" onChange={(e) => setTitle(e.target.value)} value={title}/>
                </FormControl>
                <FormControl required sx={inputStyle}>
                    <InputLabel htmlFor="subtitle">Subtitle</InputLabel>
                    <Input id="subtitle" name="subtitle" onChange={(e) => setSubtitle(e.target.value)} value={subtitle}/>
                </FormControl>
                <FormControl required sx={inputStyle}>
                    <InputLabel htmlFor="description">Description</InputLabel>
                    <Input id="description" name="description" multiline style={{ whiteSpace: 'pre-line'}} onChange={handleDescription} value={description}/>
                </FormControl>
                <input type="file" onChange={handleFileSelect}/>
                <div style={{ width: matches ? '50%' : '90%', height: 300, backgroundColor: 'black', display: 'flex', justifyContent: 'center' }}>
                    {image ? <img src={image} alt="uploaded image" style={{ height: 300, backgroundSize: 'contain' }} /> : null}
                </div>
                <FormControl required sx={inputStyle}>
                    <InputLabel htmlFor="link">Post Link</InputLabel>
                    <Input id="link" name="link" onChange={(e) => setLink(e.target.value)} value={link}/>
                </FormControl>
                <div style={{ width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', border: '1px solid black'}}>
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                        <p>Add link carousel:</p>
                        <Switch onChange={handleCarouselToggle} />
                    </div>
                    {renderCarouselForm ? 
                    <>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Button onClick={() => toggleProductLinkType("product")} variant={productLink.type === "product" ? "contained" : "text"} style={{ borderRadius: 0, background: productLink.type === "product" ? 'black' : null, color: 'white'}}>Product Link</Button>
                            <Button onClick={() => toggleProductLinkType("custom")} variant={productLink.type === "custom" ? "contained" : "text"} style={{ borderRadius: 0, background: productLink.type === "custom" ? 'black' : null, color: 'white'}}>Custom Link</Button>
                        </div>
                        {productLink.type === "custom" ?
                            <>
                                <div style={{ display: 'flex', flexDirection: 'row'}}>
                                    <input type="file" onChange={handleFileSelectCustomLink}/>
                                    <img src={linkPhotoStaging} style={{ width: 50, height: 50, objectFit: 'contain'}}/>
                                </div>
                                <FormControl sx={inputStyle}>
                                    <InputLabel htmlFor="customLink">Custom Link</InputLabel>
                                    <Input id="customLink" name="customLink" onChange={(e) => setProductLink({...productLink, link: e.target.value})} value={productLink.link}/>
                                </FormControl>
                            </> :
                            <FormControl sx={inputStyle}>
                                <InputLabel htmlFor="productLink">Product Link</InputLabel>
                                <Input id="productLink" name="productLink" onChange={(e) => setProductLink({...productLink, html: e.target.value})} value={productLink.html}/>
                            </FormControl>
                        }
                        <Button onClick={addNewLink} disabled={uploadingNewLink} style={{ background: 'black', width: matches ? '50%' : '90%', margin: 5, color: 'white' }}>{!uploadingNewLink ? '+ New Link' : 'Uploading New Link...'}</Button>
                        {productLinks.length > 0 ? <Carousel links={productLinks} setLinks={setProductLinks} /> : <p>Add at least one link to show the carousel</p>}
                    </> : null}
                </div>
                <Button sx={{ background: 'black', width: matches ? '50%' : '90%', margin: 5 }} type="submit" variant='contained' disabled={submitting}>
                {submitting ? "Submitting..." : "Submit"}
                </Button>
                </> : 
                <>
                    <h2 style={{ fontFamily: 'AgrandirRegular', fontWeight: 'bold', fontSize: 32}}>Admin Sign In</h2>
                    <FormControl required sx={inputStyle}>
                        <InputLabel htmlFor="email">Email</InputLabel>
                        <Input id="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email}/>
                    </FormControl>
                    <FormControl required sx={inputStyle}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input type={'password'} id="password" name="password" onChange={(e) => setPassword(e.target.value)} value={password}/>
                    </FormControl>
                    <Button sx={{ background: 'black', width: matches ? '50%' : '90%', margin: 5 }} type="submit" variant='contained' disabled={loading}>
                        {loading ? "Submitting..." : "Submit"}
                    </Button>
                </>}
            </Box>
        </div>
    );
}

export default Dashboard;