/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://sorutrklnbgydfkt3toa3g7nnm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7lqbvmjqorc2lfhdridwwvbhaa",
    "aws_cognito_identity_pool_id": "us-east-1:e1b44232-e651-4fd3-8321-242ce03c5344",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6ZEQUsbNP",
    "aws_user_pools_web_client_id": "52h9q8te6k9obn6okreshf56um",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "stridesinstyled800e9656a3b44fcb1c2fcb6e647546e03630-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
