import "./App.css";
import Fashion from "./pages/Fashion";
import HomeDecor from "./pages/HomeDecor";
import Layout from "./pages/Layout";
import Main from "./pages/Main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Running from "./pages/Running";
import Wedding from "./pages/Wedding";
import GiftGuide from "./pages/GiftGuide";
import AboutMe from "./pages/AboutMe";
import Dashboard from "./pages/Dashboard";
import UserContext from "./context/UserContext";
import { Amplify, Auth, Hub } from "aws-amplify";
import awsconfig from "./aws-exports";
import { useEffect, useState } from "react";
Amplify.configure(awsconfig);

function App() {
  const [user, setUser] = useState(null);

  // called when app is first started and during auth events (sign in / out)
  const checkUser = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      console.log(authUser);
      setUser(authUser);
    } catch (e) {
      console.log(e);
      setUser(null);
    }
  };

  useEffect(() => {
    checkUser();
  }, []);

  useEffect(() => {
    const listener = (data) => {
      console.log(data.payload);
      if (data.payload.event === "signIn" || data.payload.event === "signOut") {
        console.log(`${data.payload.event} event just occurred`);
        checkUser();
      }
    };

    Hub.listen("auth", listener);
    return () => Hub.remove("auth", listener);
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Main />} />
            <Route path="fashion" element={<Fashion />} />
            <Route path="decor" element={<HomeDecor />} />
            <Route path="running" element={<Running />} />
            <Route path="wedding" element={<Wedding />} />
            <Route path="gifts" element={<GiftGuide />} />
            <Route path="about" element={<AboutMe />} />
            <Route path="admin" element={<Dashboard />} />
            <Route path="*" element={<Main />} />
          </Route>
          <Route path="*" element={<Main />} />
        </Routes>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
