/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      title
      subtitle
      link
      photo {
        fileName
        id
        createdAt
        updatedAt
        __typename
      }
      description
      category
      comments {
        items {
          comment
          name
          id
          createdAt
          updatedAt
          postCommentsId
          __typename
        }
        nextToken
        __typename
      }
      likes
      productLinks {
        items {
          type
          html
          link
          id
          createdAt
          updatedAt
          postProductLinksId
          productLinkPhotoId
          __typename
        }
        nextToken
        __typename
      }
      id
      createdAt
      updatedAt
      postPhotoId
      __typename
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        title
        subtitle
        link
        photo {
          fileName
          id
          createdAt
          updatedAt
          __typename
        }
        description
        category
        comments {
          items {
            comment
            name
            id
            createdAt
            updatedAt
          }
          __typename
        }
        likes
        productLinks {
          items {
            type
            html
            link
            id
            createdAt
            updatedAt
            postProductLinksId
            productLinkPhotoId
          }
          __typename
        }
        id
        createdAt
        updatedAt
        postPhotoId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      fileName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        fileName
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      comment
      name
      id
      createdAt
      updatedAt
      postCommentsId
      __typename
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        comment
        name
        id
        createdAt
        updatedAt
        postCommentsId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProductLink = /* GraphQL */ `
  query GetProductLink($id: ID!) {
    getProductLink(id: $id) {
      type
      html
      photo {
        fileName
        id
        createdAt
        updatedAt
        __typename
      }
      link
      id
      createdAt
      updatedAt
      postProductLinksId
      productLinkPhotoId
      __typename
    }
  }
`;
export const listProductLinks = /* GraphQL */ `
  query ListProductLinks(
    $filter: ModelProductLinkFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        type
        html
        photo {
          fileName
          id
          createdAt
          updatedAt
          __typename
        }
        link
        id
        createdAt
        updatedAt
        postProductLinksId
        productLinkPhotoId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
